import { type UserInfo, type UserStatus } from '../models/user';
import { Injectable, inject } from '@angular/core';
import { Subject, type Observable } from 'rxjs';
import { HttpService } from './http.service';
import { debounceTime } from 'rxjs/operators';
import { UserService } from './user.service';
import { v4 as uuidv4 } from 'uuid';

const DEBOUNCE_TIME = 500;
const STATUS_REFRESH_TIME = 60000;

export interface UserActivityInfo {
  userId?: number;
  isActive?: boolean;
  isFavorite?: boolean;
  contactStatus?: UserStatus;
  logoUrl?: string;
  updatedAt?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);
  public userData: Record<number, UserActivityInfo> = {};

  private readonly _statusUpdated$ = new Subject<void>();
  private readonly statusesRequired = new Subject();

  constructor() {
    this.statusesRequired.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(() => {
      this.getUserStatuses();
    });

    this.userService.isAuthenticated$.subscribe(() => {
      this.userData = {};
    });
  }

  get statusUpdated$(): Observable<void> {
    return this._statusUpdated$.asObservable();
  }

  public getAccountStatus(id: number): UserStatus {
    return this.userData[id]?.contactStatus;
  }

  public getActiveStatus(id: number): boolean {
    if (!this.userData[id]) {
      this.userData[id] = {};
    }

    if (this.userData[id].isActive !== undefined) {
      return this.userData[id].isActive;
    } else {
      this.statusesRequired.next(null);
      return false;
    }
  }

  public getFavoriteStatus(id: number): boolean {
    return this.userData[id]?.isFavorite;
  }

  public getLogoUrl(user: UserInfo): string {
    const id = this.getUserId(user);

    if (!this.userData[id]) {
      this.userData[id] = {};
    }

    if (this.userData[id].logoUrl) {
      return this.userData[id].logoUrl;
    }

    const url = user?.logoUrl || user?.userLogoUrl;

    if (!url) {
      return null;
    }

    if (url.indexOf('http') !== -1) {
      this.userData[id].logoUrl = `${url}?v=${uuidv4()}`;
    } else {
      this.userData[id].logoUrl = url;
    }

    return this.userData[id].logoUrl;
  }

  public getUserStatuses(): void {
    const userIds = [];
    const currentDate = new Date().getTime();
    for (const id in this.userData) {
      if (
        this.userData[id].updatedAt === undefined ||
        currentDate - this.userData[id].updatedAt > STATUS_REFRESH_TIME
      ) {
        userIds.push(id);
      }
    }

    if (userIds.length === 0) {
      return;
    }

    this.http
      .get(`api/users/status?${userIds.map((id) => `userIds=${id}`).reduce((p, c) => `${p}&${c}`)}`)
      .then((data: UserActivityInfo[]) => {
        data.forEach((activityInfo) => {
          this.userData[activityInfo.userId] = {
            ...activityInfo,
            updatedAt: currentDate,
          };
        });
        this._statusUpdated$.next();
      });
  }

  private getUserId(user: UserInfo) {
    return user.id || user.userId || user.associatedUserId;
  }
}
